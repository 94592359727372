import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';

const app = document.getElementById('inertia-app');

if (app && app.dataset.page) {
    createInertiaApp({
        id: 'inertia-app',
        resolve: (name) => import(`./pages/public/${name}`),
        setup({ el, App, props }) {
            createRoot(el).render(<App {...props} />);
        },
    });
}
